// Generated by Framer (cfa3f9f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Tab from "https://framerusercontent.com/modules/M42MM5QoUdAgIsIpdSJx/tzXSeR7bB4rZke2ocz0Z/VAMUDsO6f.js";
const TabFonts = getFonts(Tab);

const cycleOrder = ["TAZ0aBQFZ", "FPsOtNGNo", "ykH80cE1A"];

const variantClassNames = {FPsOtNGNo: "framer-v-2vs2ze", TAZ0aBQFZ: "framer-v-u2fkoh", ykH80cE1A: "framer-v-n431pj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Tab 1": "TAZ0aBQFZ", "Tab 2": "FPsOtNGNo", "Tab 3": "ykH80cE1A"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tab1?: string; tab2?: string; tab3?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "TAZ0aBQFZ", tab1: MSGp_zYf0 = "Tab 1", tab2: f72tcvvi2 = "Tab 2", tab3: GuQmBQnFI = "Tab 3", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "TAZ0aBQFZ", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const tap1k72cex = activeVariantCallback(async (...args) => {
setVariant("TAZ0aBQFZ")
})

const taps2yjdd = activeVariantCallback(async (...args) => {
setVariant("FPsOtNGNo")
})

const tapn224xy = activeVariantCallback(async (...args) => {
setVariant("ykH80cE1A")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-7EWPK", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-u2fkoh", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"TAZ0aBQFZ"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({FPsOtNGNo: {"data-framer-name": "Tab 2"}, ykH80cE1A: {"data-framer-name": "Tab 3"}}, baseVariant, gestureVariant)}><motion.div className={"framer-iq4k2k-container"} layoutDependency={layoutDependency} layoutId={"fw7_iQwQb-container"} transition={transition}><Tab height={"100%"} id={"fw7_iQwQb"} layoutId={"fw7_iQwQb"} title={MSGp_zYf0} variant={"Ho9BqSUe0"} width={"100%"} {...addPropertyOverrides({FPsOtNGNo: {tap: tap1k72cex, variant: "ym0P2kopY"}, ykH80cE1A: {tap: tap1k72cex, variant: "ym0P2kopY"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-6id4sb-container"} layoutDependency={layoutDependency} layoutId={"IwrGKm9xF-container"} transition={transition}><Tab height={"100%"} id={"IwrGKm9xF"} layoutId={"IwrGKm9xF"} tap={taps2yjdd} title={f72tcvvi2} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({FPsOtNGNo: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-tjm2r2-container"} layoutDependency={layoutDependency} layoutId={"d0waYTfPX-container"} transition={transition}><Tab height={"100%"} id={"d0waYTfPX"} layoutId={"d0waYTfPX"} tap={tapn224xy} title={GuQmBQnFI} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({ykH80cE1A: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-7EWPK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7EWPK * { box-sizing: border-box; }", ".framer-7EWPK .framer-1owj0t9 { display: block; }", ".framer-7EWPK .framer-u2fkoh { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-7EWPK .framer-iq4k2k-container, .framer-7EWPK .framer-6id4sb-container, .framer-7EWPK .framer-tjm2r2-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-7EWPK .framer-u2fkoh { gap: 0px; } .framer-7EWPK .framer-u2fkoh > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-7EWPK .framer-u2fkoh > :first-child { margin-left: 0px; } .framer-7EWPK .framer-u2fkoh > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 275
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"FPsOtNGNo":{"layout":["auto","auto"]},"ykH80cE1A":{"layout":["auto","auto"]}}}
 * @framerVariables {"MSGp_zYf0":"tab1","f72tcvvi2":"tab2","GuQmBQnFI":"tab3"}
 */
const FramerqRlbtLrYV: React.ComponentType<Props> = withCSS(Component, css, "framer-7EWPK") as typeof Component;
export default FramerqRlbtLrYV;

FramerqRlbtLrYV.displayName = "Elements/Tabs";

FramerqRlbtLrYV.defaultProps = {height: 49, width: 275};

addPropertyControls(FramerqRlbtLrYV, {variant: {options: ["TAZ0aBQFZ", "FPsOtNGNo", "ykH80cE1A"], optionTitles: ["Tab 1", "Tab 2", "Tab 3"], title: "Variant", type: ControlType.Enum}, MSGp_zYf0: {defaultValue: "Tab 1", displayTextArea: false, title: "Tab 1", type: ControlType.String}, f72tcvvi2: {defaultValue: "Tab 2", displayTextArea: false, title: "Tab 2", type: ControlType.String}, GuQmBQnFI: {defaultValue: "Tab 3", displayTextArea: false, title: "Tab 3", type: ControlType.String}} as any)

addFonts(FramerqRlbtLrYV, [...TabFonts])